import { lazyLoadJqueryDatetimepicker } from "../../lazy-load";

export default async function initDatepicker() {
  if (!document.querySelector("#datepicker")) return;

  await lazyLoadJqueryDatetimepicker();

  jQuery.datetimepicker.setLocale("ru");

  jQuery("#datepicker").datetimepicker({
    format: "d.m.Y",
    timepicker: false
  });
}
