import {breakpointAbove, breakpointHeaderMobile, breakpointHeaderTablet} from "../../../utils/breakpoints";
import throttle from "lodash/throttle";
import {override} from "../core";

export default function autoLoadMore(options) {
  return view => {
    view.autoLoadMore = {};

    view.autoLoadMore.getBreakpoint = () => {
      const isAboveTablet = breakpointAbove(breakpointHeaderTablet);
      const isAboveMobile = breakpointAbove(breakpointHeaderMobile);

      if (isAboveTablet) {
        return "xl";
      } else if (isAboveMobile) {
        return "md";
      } else {
        return "sm";
      }
    };

    view.autoLoadMore.getMaxPages = () => {
      let max = options[view.autoLoadMore.getBreakpoint()];
      if (!max) max = 1;
      return max;
    };

    view.autoLoadMore.update = () => {
      const bottom = view.contentRoot.getBoundingClientRect().bottom;
      const maxPages = view.autoLoadMore.getMaxPages();

      const isInRange = bottom < window.innerHeight * 2;
      const canLoad = view.currentPage < maxPages && view.hasMore;

      if (isInRange && canLoad && !view.isLoading) {
        view.loadMore();
      }
    };

    view.autoLoadMore.updateThrottled = throttle(view.autoLoadMore.update, 1000);

    document.addEventListener("scroll", () => {
      view.autoLoadMore.updateThrottled();
    });

    view.reload = override(view.reload, async (callSuper, extraParams) => {
      const resp = await callSuper(extraParams);
      view.autoLoadMore.updateThrottled();
      return resp;
    });

    view.autoLoadMore.updateThrottled();

    return view;
  };
}
