import {
  CONCEALED_PHONE_REGEXP,
  PHONE_REGEXP
} from "../../utils/validation_methods";
import { mapErrorsToForm, post, showSuccessFancybox } from "../../../modals";
import { disableConversionOnLeaving } from "../modals/conversion-on-leaving";
import { lazyLoadJqueryValidation, whenVisible } from "../lazy-load";
import { addSpinnerOnFormBtn, removeSpinnerOnFormBtn } from "../../utils/utils";

export default function initStockRequest() {
  initModalForms();

  let elements = document.querySelectorAll(".stock-request");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    element.addEventListener("click", event => {
      event.preventDefault();
      handleButtonClick(element);
    });
  }
}

const TYPE_NUMBER = "number";
const TYPE_TARIFF = "tariff";

const MODAL_NUMBER = "#number_stock_request_modal";
const MODAL_TARIFF = "#tariff_stock_request_modal";

function handleButtonClick(baseElement) {
  let elementType = baseElement.getAttribute("data-type");
  let elementId = baseElement.getAttribute("data-id");
  let elementDisplay = baseElement.getAttribute("data-display");

  let modalSelector, idInputSelector, displayInputSelector;
  if (elementType === TYPE_NUMBER) {
    modalSelector = MODAL_NUMBER;
    idInputSelector = '[name="origin_number"]';
    displayInputSelector = '[name="desired_number"]';
  } else if (elementType == TYPE_TARIFF) {
    modalSelector = MODAL_TARIFF;
    idInputSelector = '[name="tariff"]';
    displayInputSelector = "[data-tariff-display]";
  } else {
    console.error(`Invalid stock-request data-type: ${elementType}`);
    return;
  }

  let modal = document.querySelector(modalSelector);
  $.fancybox.open(modal);

  let idInput = modal.querySelector(idInputSelector);
  if (idInput) {
    idInput.value = elementId;
    idInput.dispatchEvent(new Event("input", { bubbles: true }));
    idInput.dispatchEvent(new Event("change", { bubbles: true }));
  }

  let displayInput = modal.querySelector(displayInputSelector);
  if (displayInput) {
    displayInput.value = elementDisplay;
    displayInput.dispatchEvent(new Event("input", { bubbles: true }));
    displayInput.dispatchEvent(new Event("change", { bubbles: true }));
  }
}

function initModalForms() {
  let commonRules = {
    client_name: {
      required: true
    },
    client_phone: {
      required: true,
      regexp: PHONE_REGEXP
    },
    client_email: {
      required: false,
      email: true
    }
  };

  initModalForm({
    modalSelector: MODAL_NUMBER,
    validate: {
      rules: Object.assign({}, commonRules, {
        origin_number: {
          required: false
        },
        desired_number: {
          required: true,
          regexp: CONCEALED_PHONE_REGEXP
        }
      })
    }
  });

  initModalForm({
    modalSelector: MODAL_TARIFF,
    validate: {
      rules: Object.assign({}, commonRules, {
        tariff: {
          required: false
        }
      })
    }
  });
}

function initModalForm(options) {
  let modal = document.querySelector(options.modalSelector);
  let form = modal.querySelector("[data-stock-request-form]");
  let action = form.getAttribute("data-action");
  let submitButton = form.querySelector("button[type=submit]");
  let submitButtonText = submitButton.getAttribute("data-original-text");
  if (!submitButtonText) {
    submitButtonText = submitButton.innerText;
    submitButton.setAttribute("data-original-text", submitButtonText);
  }

  let $form = $(form);
  let $submitButton = $(submitButton);

  whenVisible(form, async () => {
    await lazyLoadJqueryValidation();

    let validator = $form.validate(options.validate);

    form.addEventListener("submit", function(e) {
      e.preventDefault();

      if ($form.valid()) {
        let data = $form.serializeObject();

        addSpinnerOnFormBtn($submitButton);
        post(action, data)
          .done(function() {
            $form.find("input, textarea").val("");
            removeSpinnerOnFormBtn($submitButton, submitButtonText);
            showSuccessFancybox();
            disableConversionOnLeaving();
          })
          .fail(function(response) {
            let errors = JSON.parse(response.responseText);
            mapErrorsToForm(errors, validator);
            removeSpinnerOnFormBtn($submitButton, submitButtonText);
          });
      }
    });
  });
}
