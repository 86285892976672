import GenericFlatForm from "./generic-flat-form";
import { prepareFormErrors, removeSpinnerOnFormBtn } from "../../utils/utils";
import { mapErrorsToForm, showSuccessFancybox } from "../../../modals";
import { disableConversionOnLeaving } from "../modals/conversion-on-leaving";

export default class SuccessMessageFlatForm extends GenericFlatForm {
  constructor(htmlElement, validator, successFancyBoxSelector) {
    super(htmlElement, validator);
    this.successFancyBoxSelector = successFancyBoxSelector;
  }

  doneRequest(response) {
    if (response.status === "ok") {
      this.baseElement[0].reset();
      removeSpinnerOnFormBtn(this.button, this.buttonText);
      showSuccessFancybox(this.successFancyBoxSelector);
      disableConversionOnLeaving();
    } else {
      let errors = prepareFormErrors(response.errors);
      mapErrorsToForm(errors, this.elementValidator);
      removeSpinnerOnFormBtn(this.button, this.buttonText);
    }
  }
}
