import { lazyLoadNoUiSlider } from "../lazy-load";
import { prettyNum } from "../../utils/utils";

class CallsDirectionSlider {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.slider = this.baseElement.querySelector(
      ".calls-direction-slider__slider"
    );
    this.sameProviderInput = this.baseElement.querySelector(
      "[data-same-provider]"
    );
    this.otherProvidersInput = this.baseElement.querySelector(
      "[data-other-providers]"
    );
    this.internationalInput = this.baseElement.querySelector(
      "[data-international]"
    );

    this.sameProvider = 0;
    this.otherProviders = 0;

    this.setSameProvider(this.sameProviderInput.value);
    this.setOtherProviders(this.otherProvidersInput.value);

    this.init();
  }

  async init() {
    const { default: noUiSlider } = await lazyLoadNoUiSlider();

    noUiSlider.create(this.slider, {
      range: {
        min: 0,
        max: 100
      },
      step: 1,
      start: [this.sameProvider, this.getOtherProvidersSummed()],
      connect: [true, true, false],
      format: {
        to: Number,
        from: prettyNum
      }
    });

    this.slider.noUiSlider.on("update", (values, handle) => {
      if (handle === 0) {
        this.setSameProvider(values[handle]);
      } else if (handle === 1) {
        this.setOtherProvidersSummed(values[handle]);
      }
      this.sameProviderInput.value = this.sameProvider;
      this.otherProvidersInput.value = this.otherProviders;
      this.internationalInput.value = this.getInternational();
    });

    this.sameProviderInput.addEventListener("change", () => {
      this.setSameProvider(this.sameProviderInput.value);
      this.slider.noUiSlider.set([
        this.sameProvider,
        this.getOtherProvidersSummed()
      ]);
    });

    this.otherProvidersInput.addEventListener("change", () => {
      this.setOtherProviders(this.otherProvidersInput.value);
      this.slider.noUiSlider.set([
        this.sameProvider,
        this.getOtherProvidersSummed()
      ]);
    });

    this.internationalInput.addEventListener("change", () => {
      this.setInternational(this.internationalInput.value);
      this.slider.noUiSlider.set([
        this.sameProvider,
        this.getOtherProvidersSummed()
      ]);
    });
  }

  setSameProvider(value) {
    value = Math.min(value, 100);
    const otherProvidersSummed = this.getOtherProvidersSummed();
    this.sameProvider = value;
    this.otherProviders = Math.max(otherProvidersSummed - this.sameProvider, 0);
  }

  setOtherProviders(value) {
    value = Math.min(value, 100);
    value = Math.max(value, 0);
    this.otherProviders = value;
    if (this.sameProvider + this.otherProviders > 100) {
      this.sameProvider = 100 - this.otherProviders;
    }
  }

  getOtherProvidersSummed() {
    return this.sameProvider + this.otherProviders;
  }

  setOtherProvidersSummed(value) {
    this.setOtherProviders(value - this.sameProvider);
  }

  getInternational() {
    const international = 100 - this.otherProviders - this.sameProvider;
    return Math.max(international, 0);
  }

  setInternational(value) {
    value = Math.min(value, 100);

    // Подгоняем sameProvider
    if (this.sameProvider + this.otherProviders + value < 100) {
      this.sameProvider = 100 - value - this.otherProviders;
    }
    if (this.sameProvider + this.otherProviders + value > 100) {
      this.sameProvider = 100 - value - this.otherProviders;
      this.sameProvider = Math.max(this.sameProvider, 0);
    }

    // Подгоняем otherProviders
    if (this.sameProvider + this.otherProviders + value > 100) {
      this.otherProviders = 100 - value - this.sameProvider;
      this.otherProviders = Math.max(this.otherProviders, 0);
    }
  }
}

export default function initCallsDirectionSlider() {
  const elements = document.querySelectorAll(".calls-direction-slider");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new CallsDirectionSlider(element);
  }
}
