import toastr from "toastr";
import {loadCart, setCartPromocode} from "../../react/modules/cart";
import { store } from "../../react/store";
import {updateCartCreator} from "../../react/reducers/cart";

async function loadPromocode() {
  let searchParams = new URLSearchParams(location.search);
  let promocode = searchParams.get("promocode");
  if (!promocode) {
    return;
  }

  try {
    await setCartPromocode(promocode);
  } catch (e) {
    toastr.success(
      "Вы перешли по ссылке на промокод, но он больше не действует",
      "Промокод не действует"
    );
    return;
  }

  loadCart().done(function(data) {
    store.dispatch(updateCartCreator(data));
  });

  toastr.success(
    "Вы можете найти его в корзине при оформлении заказа",
    "Промокод применен"
  );
}

export default function initPromocodeQueryLoader() {
  loadPromocode();
}
