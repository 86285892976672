import "../js";

import "../styles/topnomer/desktop.scss";
import "tooltipster/dist/css/tooltipster.bundle.min.css";
import "tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-borderless.min.css";
import "swiper/swiper-bundle.css";

import { initErrorPages } from "../desktop/error_pages";

$(function() {
  initErrorPages();
});
