import {breakpointBelow, breakpointHeaderMobile} from "../../utils/breakpoints";


function handleHeaderClick(event) {
  const link = event.target;
  const selfItem = link.closest(".footer-menu__item");
  const body = selfItem.querySelector(".footer-menu__item-body");

  if (breakpointBelow(breakpointHeaderMobile)) {
    // На мобильных экранах меню открывается по клику на заголовок. Отменяем переход по ссылке в заголовке.
    event.preventDefault();
    body.style.setProperty("--expanded-height", `${body.scrollHeight}px`);
    selfItem.classList.toggle("footer-menu__item--active");
  }
}

export default function initFooterMenu() {
  const itemLinks = Array.prototype.slice.apply(document.querySelectorAll(".footer-menu__header"));
  itemLinks.forEach(link => {
    link.addEventListener("click", event => handleHeaderClick(event));
  });
}
