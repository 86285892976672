const PRELOADER_CLASS = "preloader-xsmall";

export function prettyNum(num) {
  if (typeof num === "string") {
    return num;
  }
  return num
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
}

export function getPrice(price) {
  if (typeof price === "string") {
    return parseFloat(price.replace(/\s+/, "").replace(",", "."));
  } else if (typeof price === "number") {
    return price;
  } else {
    return null;
  }
}

/**
 * Разбивает список array на подсписки из size элементов.
 * @param array
 * @param size
 * @returns {Array} список списков
 */
export function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }

  return chunked_arr;
}

/**
 * Разбивает поддомены на список словарей по алфавиту.
 * @param subdomains
 * @returns {Array} [{A:[Алтай, ...]}, {...}]
 */
export function getSubdomainsByLetter(subdomains) {
  let regSort = [...subdomains].sort((a, b) =>
      a.display_name.localeCompare(b.display_name)
    ),
    resDct = {},
    resLst = [];

  regSort.forEach(subdomain => {
    let letter = subdomain.display_name[0];

    if (!(letter in resDct)) {
      resDct[letter] = [];
    }
    resDct[letter].push(subdomain);
  });

  // Преобразуем словарь в список словарей.
  for (var key in resDct) {
    resLst.push({
      letter: key,
      subdomains: resDct[key]
    });
  }
  return resLst;
}

/**
 * Устанавливает спиннер на кнопку.
 * @param btn кнопка
 * @param options опции:
 *  fixWidth: boolean - Зафиксировать ширину кнопки, чтобы она не уменьшилась из-за вставки лоадера.
 */
export function addSpinnerOnFormBtn(btn, options) {
  const defaultOptions = { fixWidth: false };
  options = Object.assign({}, defaultOptions, options);

  let width;
  if (options.fixWidth) {
    width = btn.outerWidth();
  }

  let preloader = '<div class="'.concat(PRELOADER_CLASS, '"></div>');
  btn.html(preloader);
  btn.prop("disabled", true);

  if (options.fixWidth) {
    btn.css("width", `${width}px`);
  }
}

/**
 * Снимает спиннер с кнопки, возвращает текст кнопки
 * @param btn кнопка
 * @param btnText текст для кнопки
 * @param options опции:
 *  unfixWidth: boolean - Отменить фиксирование ширины кнопки, обратный эффект для опции fixWidth от addSpinnerOnFormBtn.
 */
export function removeSpinnerOnFormBtn(btn, btnText, options) {
  const defaultOptions = { unfixWidth: false };
  options = Object.assign({}, defaultOptions, options);

  btn.find(PRELOADER_CLASS).detach();
  btn.prop("disabled", false);
  btn.text(btnText);

  if (options.unfixWidth) {
    btn.css("width", "");
  }
}

/**
 * Добавляет анимацию загрузки на кнопку классом preloader.
 * @param {HTMLElement} button
 */
export function addButtonPreloader(button) {
  // Зафиксируем ширину, чтобы оне не скакала из-за стилей preloader
  let width = button.getBoundingClientRect().width;
  button.style.width = `${width}px`;

  button.classList.add("preloader", "preloader--active");
  if (button.classList.contains("button-gray") || button.classList.contains("button-more")) {
    button.classList.add("preloader--gray");
  }
}

/**
 * Отменяет действие функции addButtonPreloader.
 * @param {HTMLElement} button
 */
export function removeButtonPreloader(button) {
  button.classList.remove("preloader", "preloader--active", "preloader--gray");
  button.style.width = "";
}

/**
 * Преобразует json из ошибок из Django-Form в конструкцию подходящую для JQuery.validation
 * @param formErrors - исходный json ошибок
 * @returns {Object} {field:errorMessage}
 */

export function prepareFormErrors(formErrors) {
  let result = {};
  for (const key in formErrors) {
    let errors = formErrors[key];
    let strError = "";
    for (let i = 0; i < errors.length; i++) {
      strError += errors[i].message + " ";
    }
    result[key] = strError;
  }
  return result;
}

/**
 * Возвращает промис, который резолвится после обновления разметки страницы.
 * Удобно, если код добавил на страницу элемент и нужно продолжить работу после того,
 * как браузер перестроил позиции и размеры.
 */
export function layoutUpdate() {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, 0);
  });
}

/**
 * setTimeout для асинхронных функций.
 */
export function timeout(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}

export function escapeHTML(text) {
  return text.replace(/[&<>"']/g, match => {
    return {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#39;"
    }[match];
  });
}

// https://gist.github.com/tomfun/830fa6d8030d16007bbab50a5b21ef97
export function plural(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

export function randomString() {
  return Math.random().toString(16).substring(2);
}

export function getClearableInputs(root) {
  const fields = [];
  const inputs = root.querySelectorAll("input");
  for (const input of inputs) {
    const isToggle = input.hasAttribute("data-toggle");

    if (input.type === "hidden") {
      if (isToggle) {
        fields.push({
          input,
          isClear: () => input.disabled,
          clear: () => input.disabled = true,
        });
      }
      continue;
    }

    if (input.type === "checkbox") {
      fields.push({
        input,
        isClear: () => !input.checked,
        clear: () => input.checked = false,
      })
    } else {
      fields.push({
        input,
        isClear: () => !input.value,
        clear: () => input.value = "",
      })
    }
  }
  return fields;
}
