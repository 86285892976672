import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "../store";
import SubdomainsContainer from "../components/subdomains/SubdomainsContainer";

export function initSubdomains() {
  const root = document.getElementById("subdomain-modal-city");

  ReactDOM.render(
    <Provider store={store}>
      {React.createElement(SubdomainsContainer, window.props)}
    </Provider>,
    root
  );
}
