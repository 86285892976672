import once from "lodash/once";
import {
  initIgnoreValidator,
  initLuhnMethod,
  initMaskMethod,
  initNameMethod,
  initRegexpMethod
} from "../utils/validation_methods";
import { timeout } from "../utils/utils";

/**
 * Вызывает callback, когда target близок к тому, чтобы стать видимым в окне браузера в первый раз.
 * То есть когда он будет видим и расположен на расстоянии не больше одной высоты
 * viewport'а за границей viewport'а.
 * Если target будет расположен внутри модального окна fancybox, он должен находиться в DOM
 * на момент вызова этой функции.
 * @param target HTMLElement, HTMLElement[], NodeList, JQuery<HTMLElement>
 * @param callback () => void
 */
export function whenVisible(target, callback) {
  if (!("IntersectionObserver" in window) || !("MutationObserver" in window)) {
    // Браузер не поддерживает этот API, отменяем ленивую загрузку.
    callback();
    return;
  }

  const marginY = window.innerHeight;

  const isElementVisible = element => {
    if (element.getClientRects().length === 0) return false;

    let rect = element.getBoundingClientRect();
    if (rect.x + rect.width < 0) return false;
    if (rect.x > window.innerWidth) return false;
    if (rect.y + rect.height < -marginY) return false;
    if (rect.y > window.innerHeight + marginY) return false;

    return true;
  };

  const isTargetVisible = () => {
    if (target.length !== undefined) {
      for (let i = 0; i < target.length; i++) {
        const targetItem = target[i];
        if (isElementVisible(targetItem)) {
          return true;
        }
      }
      return false;
    } else {
      return isElementVisible(target);
    }
  };

  if (isTargetVisible()) {
    callback();
    return;
  }

  let mutationObserver, intersectionObserver;
  const callbackOnce = once(callback);
  const observerHandler = () => {
    if (isTargetVisible()) {
      mutationObserver.disconnect();
      intersectionObserver.disconnect();
      callbackOnce();
    }
  };

  intersectionObserver = new IntersectionObserver(observerHandler, {
    rootMargin: `${marginY}px 0px`
  });
  mutationObserver = new MutationObserver(observerHandler);

  const observeElement = element => {
    intersectionObserver.observe(element);

    let parent = element;
    while (parent) {
      mutationObserver.observe(parent, { attributes: true, childList: true });
      parent = parent.parentElement;
    }
  };

  if (target.length !== undefined) {
    for (let i = 0; i < target.length; i++) {
      const targetItem = target[i];
      observeElement(targetItem);
    }
  } else {
    observeElement(target);
  }
}

/**
 * Делает повторные попытки загрузки динамического чанка, если загрузка падает
 * с ошибкой ChunkLoadError.
 * @param {async () => any} load Функция, загружающая чанк.
 * @returns Результат выполнения load.
 */
async function retryOnFail(load) {
  /* eslint-disable no-constant-condition */
  const MAX_ATTEMPTS = 5;
  const INITIAL_DELAY = 500; // Будет удваиваться после каждой попытки, до 4 сек.

  let attempts = 0;
  let delay = INITIAL_DELAY;

  while (true) {
    try {
      return await load();
    } catch (e) {
      if (e.name !== "ChunkLoadError") {
        throw e;
      }

      attempts++;
      if (attempts >= MAX_ATTEMPTS) {
        throw e;
      }

      await timeout(delay);
      delay *= 2;
    }
  }
}

export async function lazyLoadSlick() {
  return await retryOnFail(async () => {
    return await import(
      /* webpackChunkName: "dynamic/slick-carousel" */ "slick-carousel"
    );
  });
}

export async function lazyLoadTooltipster() {
  return await retryOnFail(async () => {
    return await import(
      /* webpackChunkName: "dynamic/tooltipster" */ "tooltipster"
    );
  });
}

const initSwiper = once(swiper => {
  const { Swiper, Navigation, Thumbs, Controller } = swiper;
  Swiper.use([Navigation, Thumbs, Controller]);
});
export async function lazyLoadSwiper() {
  return await retryOnFail(async () => {
    const swiper = await import(
      /* webpackChunkName: "dynamic/swiper" */ "swiper"
    );
    initSwiper(swiper);
    return swiper;
  });
}

export async function lazyLoadJqueryUiSlider() {
  return await retryOnFail(async () => {
    await Promise.all([
      import(
        /* webpackChunkName: "dynamic/jquery-ui-slider" */ "jquery-ui/ui/widgets/slider"
      ),
      import(
        /* webpackChunkName: "dynamic/jquery-ui-mouse" */ "jquery-ui/ui/widgets/mouse"
      )
    ]);
    await import(
      /* webpackChunkName: "dynamic/jquery-ui-touch-punch" */ "jquery-ui-touch-punch"
    );
  });
}

export async function lazyLoadJqueryDatetimepicker() {
  return await retryOnFail(async () => {
    return await import(
      /* webpackChunkName: "dynamic/jquery-datetimepicker" */ "jquery-datetimepicker"
    );
  });
}

export async function lazyLoadIMask() {
  return await retryOnFail(async () => {
    return await import(/* webpackChunkName: "dynamic/imask" */ "imask");
  });
}

export async function lazyLoadYandexShare() {
  return await retryOnFail(async () => {
    return await import(
      /* webpackChunkName: "dynamic/common/yandex-share" */ "./misc/yandex-share"
    );
  });
}

export async function lazyLoadOrderCheckout() {
  return await retryOnFail(async () => {
    if (FLAVOUR === "mobile") {
      return await import(
        /* webpackChunkName: "dynamic/mobile/react/OrderCheckout" */ "../../mobile/react/components/cart/OrderCheckout"
      );
    } else {
      return await import(
        /* webpackChunkName: "dynamic/common/react/OrderCheckout" */ "../../js/react/components/cart/OrderCheckout"
      );
    }
  });
}

export async function lazyLoadOrderResult() {
  return await retryOnFail(async () => {
    return await import(
      /* webpackChunkName: "dynamic/common/react/OrderResult" */ "../../js/react/components/cart/OrderResult"
    );
  });
}

const initJqueryValidation = once(() => {
  initRegexpMethod();
  initLuhnMethod();
  initMaskMethod();
  initNameMethod();
  initIgnoreValidator();

  $.extend($.validator.messages, {
    email: "Введите корректный e-mail",
    required: "Пожалуйста, заполните это поле",
    digits: "Введите цифры",
  });
  $.validator.setDefaults({
    errorPlacement: (error, element) => {
      if (element.hasClass("nice-select-init")) {
        element = element.nextAll(".nice-select").first();
      }
      error.insertAfter(element);
      if (element.hasClass("nice-select-init")) {
        error.addClass("error--nice-select");
      }
    },
  });
});
export async function lazyLoadJqueryValidation() {
  await retryOnFail(async () => {
    await import(
      /* webpackChunkName: "dynamic/jquery-validation" */ "jquery-validation"
    );
    initJqueryValidation();
  });
}

export async function lazyLoadNiceSelect() {
  return await retryOnFail(async () => {
    return await import(
      /* webpackChunkName: "dynamic/nice-select2" */ "../../vendor/nice-select2/js/nice-select2"
    );
  });
}

export async function lazyLoadNoUiSlider() {
  return await retryOnFail(async () => {
    return await import(
      /* webpackChunkName: "dynamic/nouislider" */ "nouislider"
    );
  });
}
