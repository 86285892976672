import numberTemplate from "../../../templates/common/number_template.ejs";
import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";
import {renderNoResultsMessage, renderProviderIcon} from "../templates";
import {store} from "../../react/store";
import transformParams from "./plugins/transform-params";
import {cleanMaskedInteger} from "../forms/inputs/masks";
import initCatalogView, {override} from "./core";
import extraSearchParams from "./plugins/extra-search-params";
import loadMore from "./plugins/load-more";
import sortSelect from "./plugins/sort-select";
import filters from "./plugins/filters";
import {getClearableInputs} from "../../utils/utils";
import autoLoadMore from "./plugins/auto-load-more";

function renderStarNumber(number) {
  const render = lodashTemplate(numberTemplate, {
    imports: {
      forEach: forEach,
      renderProviderIcon: renderProviderIcon
    }
  });
  const cartNumbers = store.getState().cart.cartNumbers;
  return render({
    isStarNumber: true,
    isSuperPair: false,
    itemObj: number,
    subdomain: SUBDOMAIN_URL,
    cartNumbers: cartNumbers,
  });
}

function renderStarNumberPage(numbers) {
  if (numbers.length === 0) {
    return renderNoResultsMessage();
  }

  const content = numbers.map(num => renderStarNumber(num)).join("");
  return `<div class="number-card-container number-card-container--sm">${content}</div>`;
}

const transformMaskedParams = transformParams({
  min_price: v => cleanMaskedInteger(v),
  max_price: v => cleanMaskedInteger(v),
});

function cartButtons(view) {
  view.starNumberForm = view.element.querySelector(".js-catalog-view__star-number-form");
  view.starNumberInput = view.starNumberForm.querySelector('input[name="number"]');

  const onClick = event => {
    console.log(event);
    event.preventDefault();

    const number = event.target.getAttribute("data-number");
    if (!number) return;

    view.starNumberInput.value = number;
    view.starNumberForm.scrollIntoView({behavior: "smooth"});
  };

  view.initCartButtons = () => {
    const buttons = view.element.querySelectorAll(".order-star-number");
    for (const button of buttons) {
      button.removeEventListener("click", onClick);
      button.addEventListener("click", onClick);
    }
  };

  view.reload = override(view.reload, async (callSuper, extraParams) => {
    const resp = await callSuper(extraParams);
    view.initCartButtons();
    return resp;
  });

  view.loadAppend = override(view.loadAppend, async (callSuper, extraParams) => {
    const resp = await callSuper(extraParams);
    view.initCartButtons();
    return resp;
  });

  view.initCartButtons();

  return view;
}

export function initCatalogViewStarNumbersCatalog() {
  const elements = document.querySelectorAll(".js-catalog-view--star-numbers-catalog");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderStarNumberPage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        cartButtons,
        autoLoadMore({md: 3, xl: 3}),
      ],
    });
  }
}

function hideIndexUi(view) {
  view.hideIndexUi = () => {
    const indexElements = view.element.querySelectorAll(".js-catalog-view__index-ui");
    for (const element of indexElements) {
      element.style.display = "none";
    }

    const catalogElements = view.element.querySelectorAll(".js-catalog-view__catalog-ui");
    for (const element of catalogElements) {
      element.style.display = "";
    }
  }

  view.showIndexUi = () => {
    const inputs = getClearableInputs(view.filterForm);
    for (const input of inputs) {
      if (!input.isClear()) {
        input.clear();
        input.input.dispatchEvent(new Event("change", {bubbles: true}));
      }
    }
    view.debounceReload.cancel();

    const indexElements = view.element.querySelectorAll(".js-catalog-view__index-ui");
    for (const element of indexElements) {
      element.style.display = "";
    }

    const catalogElements = view.element.querySelectorAll(".js-catalog-view__catalog-ui");
    for (const element of catalogElements) {
      element.style.display = "none";
    }

    if (view.buttonMore) {
      view.buttonMore.style.display = "none";
    }
  }

  view.reload = override(view.reload, async (callSuper, extraParams) => {
    try {
      return await callSuper(extraParams);
    } finally {
      view.hideIndexUi();
    }
  });

  view.clearFiltersButton = view.element.querySelector(".js-catalog-view__clear-filters");
  view.clearFiltersButton.addEventListener("click", () => {
    view.showIndexUi();
  });

  return view;
}

export function initCatalogViewStarNumbersIndex() {
  const elements = document.querySelectorAll(".js-catalog-view--star-numbers-index");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderStarNumberPage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        cartButtons,
        hideIndexUi,
      ],
    });
  }
}
