import classNames from "classnames";
import React, { Component } from "react";
import { getPrice, prettyNum } from "../../../utils/utils";
import { IsComponentMountedMixin } from "../../mixins";
import {
  handleRemoveDeviceDataLayer,
  handleRemoveNumberDataLayer,
  handleRemoveTariffDataLayer,
  loadCart,
  removeDeviceFromCartItem,
  removeNumberFromCartItem,
  removeTariffFromCartItem,
  toggleAddButtonAfterRemove,
  toggleAddRelatedDeviceButtonAfterRemove
} from "../../modules/cart";
import { store } from "../../store";
import { itemPropTypes } from "../prop_types";
import ProviderIcon from "../ProviderIcon";
import CurrencySymbol from "../CurrencySymbol";
import {updateCartCreator} from "../../reducers/cart";

export default class CartItem extends IsComponentMountedMixin(Component) {
  constructor(props) {
    super(props);

    this.state = {
      isRemovingDevice: false,
      isRemovingNumber: false,
      isRemovingTariff: false
    };
  }

  removeNumber() {
    let itemId = this.props.item.id;
    let number = this.props.item.number;
    let _this = this;

    if (this.state.isRemovingNumber) return;
    this.setState({ isRemovingNumber: true });

    removeNumberFromCartItem(itemId)
      .done(function(data) {
        handleRemoveNumberDataLayer(data);
      })
      .then(loadCart)
      .done(function(data) {
        if (number) {
          toggleAddButtonAfterRemove(number.id);
        }

        store.dispatch(updateCartCreator(data));
      })
      .always(function() {
        if (_this.isComponentMounted) {
          _this.setState({ isRemovingNumber: false });
        }
      });
  }

  removeTariff() {
    let itemId = this.props.item.id;
    let tariff = this.props.item.tariff;
    let _this = this;

    if (this.state.isRemovingTariff) return;
    this.setState({ isRemovingTariff: true });

    removeTariffFromCartItem(itemId)
      .done(function(data) {
        handleRemoveTariffDataLayer(data);
      })
      .then(loadCart)
      .done(function(data) {
        if (tariff) {
          toggleAddButtonAfterRemove(tariff.id);
        }
        store.dispatch(updateCartCreator(data));
      })
      .always(function() {
        if (_this.isComponentMounted) {
          _this.setState({ isRemovingTariff: false });
        }
      });
  }

  removeDevice() {
    let itemId = this.props.item.id;
    let device = this.props.item.device;
    let _this = this;

    if (this.state.isRemovingDevice) return;
    this.setState({ isRemovingDevice: true });

    removeDeviceFromCartItem(itemId)
      .done(function(data) {
        handleRemoveDeviceDataLayer(data);
      })
      .then(loadCart)
      .done(function(data) {
        if (device) {
          toggleAddButtonAfterRemove(device.id);
        }
        store.dispatch(updateCartCreator(data));
        const state = store.getState();
        if (!state.cart.isRelatedDevicesInCart) {
          toggleAddRelatedDeviceButtonAfterRemove();
        }
      })
      .always(function() {
        if (_this.isComponentMounted) {
          _this.setState({ isRemovingDevice: false });
        }
      });
  }

  renderDevice() {
    const device = this.props.item.device;
    if (!device) {
      return null;
    }

    let icon;
    if (device.main_image) {
      icon = (
        <img
          src={device.main_image}
          className="cart-invoice-list__icon cart-invoice-list__icon--fix-width"
        />
      );
    } else {
      icon = (
        <div className="cart-invoice-list__icon cart-invoice-list__icon--fix-width" />
      );
    }

    let tags;
    if (this.props.item.device.tags.length > 0) {
      tags = (
        <div className="cart-invoice-list__badge-container mt-4">
          {this.props.item.device.tags.map(tag => (
            <span
              key={tag.id}
              className="product-badge"
              style={{ backgroundColor: tag.color }}
            >
              {tag.name}
            </span>
          ))}
        </div>
      );
    }

    return (
      <div className="p-20">
        <div className="cart-invoice-list">
          {icon}
          <div className="cart-invoice-list__list">
            <div className="cart-invoice-list__item">
              <div className="cart-invoice-list__key">
                <a
                  href={this.props.item.host_url + device.absolute_url}
                  className="cart-invoice-list__item-link"
                >
                  {this.props.item.device.name}
                </a>
                {tags}
              </div>
              <div className="cart-invoice-list__value fw-700">
                <span className="price-inline">
                  {this.props.item.device.current_price}
                  <CurrencySymbol currency={this.props.item.device.currency} />
                </span>
              </div>
            </div>
          </div>
          <div className="cart-invoice-list__icon">
            <button
              type="button"
              className={classNames(
                "cart-invoice-list__button-delete-item",
                "button-delete-item",
                "preloader",
                "preloader--gray",
                {
                  "preloader--active": this.state.isRemovingDevice
                }
              )}
              onClick={() => this.removeDevice()}
            />
          </div>
        </div>
      </div>
    );
  }

  renderNumberAdvancePaymentItem() {
    if (!this.props.item.tariff && !this.props.item.number) {
      return null;
    }

    let tariffAdvancePayment = 0;
    if (
      this.props.item.tariff &&
      this.props.item.tariff.show_advance_payment &&
      this.props.item.tariff.advance_payment
    ) {
      tariffAdvancePayment = getPrice(this.props.item.tariff.advance_payment);
    }

    let numberAdvancePayment = 0;
    if (this.props.item.number && this.props.item.number.advance_price) {
      numberAdvancePayment = getPrice(this.props.item.number.advance_price);
    }

    if (numberAdvancePayment >= tariffAdvancePayment && numberAdvancePayment) {
      return (
        <div className="cart-invoice-list__item">
          <div className="cart-invoice-list__key">Авансовый платеж</div>
          <div className="cart-invoice-list__value fw-700">
            <span className="price-inline">
              {prettyNum(numberAdvancePayment)}
              <CurrencySymbol currency={this.props.item.number.currency} />
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderRefillItem() {
    if (!this.props.item.refill) {
      return null;
    }

    return (
      <div className="cart-invoice-list__item">
        <div className="cart-invoice-list__key">Пополнение баланса</div>
        <div className="cart-invoice-list__value fw-700">
          <span className="price-inline">
            {this.props.item.refill} <CurrencySymbol />
          </span>
        </div>
      </div>
    );
  }

  renderNumberPurchase() {
    const number = this.props.item.number;

    if (!number) {
      return null;
    }

    if (this.props.item.rent_period && number.rent_price) {
      return null;
    }

    let simTag;
    if (this.props.item.sim_type) {
      simTag = (
        <span className="sim-tag">
          <span className="sim-tag__icon icon-sim-nano" />
          <span>{this.props.item.sim_type.display_name}</span>
        </span>
      );
    }

    return (
      <div className="p-20">
        <div className="cart-invoice-list">
          <ProviderIcon
            provider={this.props.item.number.provider_object}
            small
            className="cart-invoice-list__icon"
          />
          <div className="cart-invoice-list__list">
            <div className="cart-invoice-list__item">
              <div className="cart-invoice-list__key">
                <a
                  className="cart-invoice-list__item-link monospace mr-10"
                  href={number.host_url + number.absolute_url}
                >
                  {this.props.item.number.number}
                </a>
                {simTag}
              </div>
              <div className="cart-invoice-list__value fw-700">
                <span className="price-inline">
                  {this.props.item.number.site_price}
                  <CurrencySymbol currency={this.props.item.number.currency} />
                </span>
              </div>
            </div>
            {this.renderNumberAdvancePaymentItem()}
            {this.renderRefillItem()}
          </div>
          <div className="cart-invoice-list__icon">
            <button
              type="button"
              className={classNames(
                "cart-invoice-list__button-delete-item",
                "button-delete-item",
                "preloader",
                "preloader--gray",
                {
                  "preloader--active": this.state.isRemovingNumber
                }
              )}
              onClick={() => this.removeNumber()}
            />
          </div>
        </div>
      </div>
    );
  }

  renderNumberRent() {
    const number = this.props.item.number;

    if (!number) {
      return null;
    }

    if (!this.props.item.rent_period || !number.rent_price) {
      return null;
    }

    let simTag;
    if (this.props.item.sim_type) {
      simTag = (
        <span className="sim-tag">
          <span className="sim-tag__icon icon-sim-nano" />
          <span>{this.props.item.sim_type.display_name}</span>
        </span>
      );
    }

    return (
      <div className="p-20">
        <div className="cart-invoice-list">
          <ProviderIcon
            provider={number.provider_object}
            small
            className="cart-invoice-list__icon"
          />
          <div className="cart-invoice-list__list">
            <div className="cart-invoice-list__item">
              <div className="cart-invoice-list__key">
                <a
                  className="cart-invoice-list__item-link monospace mr-10"
                  href={number.host_url + number.absolute_url}
                >
                  {number.number}
                </a>
                {simTag}
              </div>
              <div className="cart-invoice-list__value fw-700">
                <span className="price-inline">
                  {number.rent_price}
                  <CurrencySymbol currency={number.currency} />
                </span>
                /мес.
              </div>
            </div>
            <div className="cart-invoice-list__item">
              <div className="cart-invoice-list__key">Срок аренды</div>
              <div className="cart-invoice-list__value fw-700">
                {this.props.item.rent_period} мес.
              </div>
            </div>
            {this.renderNumberAdvancePaymentItem()}
            {this.renderRefillItem()}
          </div>
          <div className="cart-invoice-list__icon">
            <button
              type="button"
              className={classNames(
                "cart-invoice-list__button-delete-item",
                "button-delete-item",
                "preloader",
                "preloader--gray",
                {
                  "preloader--active": this.state.isRemovingNumber
                }
              )}
              onClick={() => this.removeNumber()}
            />
          </div>
        </div>
      </div>
    );
  }

  renderTariffAdvancePaymentItem() {
    if (!this.props.item.tariff && !this.props.item.number) {
      return null;
    }

    let tariffAdvancePayment = 0;
    if (
      this.props.item.tariff &&
      this.props.item.tariff.show_advance_payment &&
      this.props.item.tariff.advance_payment
    ) {
      tariffAdvancePayment = getPrice(this.props.item.tariff.advance_payment);
    }

    let numberAdvancePayment = 0;
    if (this.props.item.number && this.props.item.number.advance_price) {
      numberAdvancePayment = getPrice(this.props.item.number.advance_price);
    }

    if (tariffAdvancePayment > numberAdvancePayment && tariffAdvancePayment) {
      return (
        <div className="cart-invoice-list__item">
          <div className="cart-invoice-list__key">Авансовый платеж</div>
          <div className="cart-invoice-list__value fw-700">
            <span className="price-inline">
              {prettyNum(tariffAdvancePayment)}
              <CurrencySymbol currency={this.props.item.tariff.currency} />
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderTariff() {
    const tariff = this.props.item.tariff;

    if (!tariff) {
      return null;
    }

    let activationPriceItem;
    if (tariff.activation_price && getPrice(tariff.activation_price) > 0) {
      activationPriceItem = (
        <div className="cart-invoice-list__item">
          <div className="cart-invoice-list__key">Активация тарифа</div>
          <div className="cart-invoice-list__value fw-700">
            <span className="price-inline">
              {tariff.activation_price}
              <CurrencySymbol currency={tariff.currency} />
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="p-20">
        <div className="cart-invoice-list">
          <ProviderIcon
            provider={tariff.provider_object}
            small
            className="cart-invoice-list__icon"
          />
          <div className="cart-invoice-list__list">
            <div className="cart-invoice-list__item">
              <div className="cart-invoice-list__key">
                <a
                  href={tariff.host_url + tariff.absolute_url}
                  className="cart-invoice-list__item-link"
                >
                  {tariff.name}
                </a>
              </div>
              <div className="cart-invoice-list__value fw-700">
                <span className="price-inline">
                  {tariff.current_price}
                  <CurrencySymbol currency={tariff.currency} />
                </span>
              </div>
            </div>
            {activationPriceItem}
            {this.renderTariffAdvancePaymentItem()}
            <div className="cart-invoice-list__item">
              <div className="cart-invoice-list__key text-muted">
                Абонентская плата
              </div>
              <div className="cart-invoice-list__value text-muted">
                <span className="price-inline">
                  {tariff.month_fee || 0}
                  <CurrencySymbol currency={tariff.currency} />
                </span>
                /мес.
              </div>
            </div>
          </div>
          <div className="cart-invoice-list__icon">
            <button
              type="button"
              className={classNames(
                "cart-invoice-list__button-delete-item",
                "button-delete-item",
                "preloader",
                "preloader--gray",
                {
                  "preloader--active": this.state.isRemovingTariff
                }
              )}
              onClick={() => this.removeTariff()}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="small-card-container">
        {this.renderDevice()}
        {this.renderNumberPurchase()}
        {this.renderNumberRent()}
        {this.renderTariff()}
      </div>
    );
  }
}

CartItem.propTypes = {
  item: itemPropTypes.isRequired
};
