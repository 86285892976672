import { layoutUpdate, timeout } from "../../utils/utils";

class Item {
  constructor(baseElement) {
    this.baseElement = baseElement;
  }

  get title() {
    return this.baseElement.querySelector(".info-accordion__title");
  }

  get content() {
    return this.baseElement.querySelector(".info-accordion__content");
  }
}

class InfoAccordion {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.transitionDuration = 0.3; // В секундах

    this.initTransitions();

    for (const item of this.items) {
      item.title.addEventListener("click", () => {
        this.toggle(item);
      });
    }
  }

  initTransitions() {
    for (const item of this.items) {
      item.baseElement.style.transitionProperty = "padding, margin";
      item.baseElement.style.transitionDuration = `${this.transitionDuration}s`;
      item.title.style.transitionProperty = "opacity, margin";
      item.title.style.transitionDuration = `${this.transitionDuration}s`;
      item.content.style.transitionProperty = "height";
      item.content.style.transitionDuration = `${this.transitionDuration}s`;
    }
  }

  get items() {
    let items = [];
    let itemElements = this.baseElement.querySelectorAll(
      ".info-accordion__item"
    );
    for (let i = 0; i < itemElements.length; i++) {
      const element = itemElements[i];
      let item = new Item(element);
      items.push(item);
    }
    return items;
  }

  open(item) {
    item.baseElement.classList.add("info-accordion__item--active");
    item.baseElement.classList.remove("info-accordion__item--closing");
    let targetContentHeight = item.content.scrollHeight;
    item.content.style.height = "0px";

    return layoutUpdate().then(() => {
      item.content.style.height = `${targetContentHeight}px`;

      return timeout(this.transitionDuration * 1000).then(() => {
        item.content.style.height = "";
      });
    });
  }

  close(item) {
    item.baseElement.classList.remove("info-accordion__item--active");
    item.baseElement.classList.add("info-accordion__item--closing");
    item.content.style.height = `${item.content.scrollHeight}px`;

    return layoutUpdate().then(() => {
      item.content.style.height = "0px";

      return timeout(this.transitionDuration * 1000).then(() => {
        item.baseElement.classList.remove("info-accordion__item--closing");
        item.content.style.height = "";
      });
    });
  }

  isOpen(item) {
    return item.baseElement.classList.contains("info-accordion__item--active");
  }

  toggle(item) {
    if (this.isOpen(item)) {
      this.close(item);
    } else {
      this.open(item);
    }
  }
}

export default function initInfoAccordion() {
  let elements = document.querySelectorAll(".info-accordion");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new InfoAccordion(element);
  }
}
