import { post, showSuccessFancybox, mapErrorsToForm } from "../../../modals";
import { PHONE_REGEXP } from "../../utils/validation_methods";
import { addSpinnerOnFormBtn, removeSpinnerOnFormBtn } from "../../utils/utils";
import { lazyLoadJqueryValidation, whenVisible } from "../lazy-load";
import { disableConversionOnLeaving } from "../modals/conversion-on-leaving";

const TARIFF_SELECTION_REQUEST_API_URL =
  "/api/forms/create-tariff-selection-request/";

export function initTariffSelectionModal() {
  const form = $("#tariff-selection-form"),
    btn = form.find(".button"),
    btnText = btn.text().trim();

  if (form.length === 0) return;

  whenVisible(form, async () => {
    await lazyLoadJqueryValidation();

    let validator = form.validate({
      rules: {
        minutes: {
          required: true,
          digits: true
        },
        sms: {
          required: true,
          digits: true
        },
        megabytes: {
          required: true,
          digits: true
        },
        comment: {
          required: false
        },
        contact_person: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        contact_number: {
          required: true,
          regexp: PHONE_REGEXP
        }
      }
    });

    form.on("submit", function(e) {
      const form = $(this);

      if (form.valid()) {
        let data = form.serializeObject();

        addSpinnerOnFormBtn(btn);
        post(TARIFF_SELECTION_REQUEST_API_URL, data)
          .done(function() {
            form.find("input, textarea").val("");
            removeSpinnerOnFormBtn(btn, btnText);
            showSuccessFancybox();
            disableConversionOnLeaving();
          })
          .fail(function(response) {
            let errors = JSON.parse(response.responseText);
            mapErrorsToForm(errors, validator);
            removeSpinnerOnFormBtn(btn, btnText);
          });
      }

      e.preventDefault();
    });
  });
}
