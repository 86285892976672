import { post, showSuccessFancybox, mapErrorsToForm } from "../../../modals";
import { PHONE_REGEXP } from "../../utils/validation_methods";
import { addSpinnerOnFormBtn, removeSpinnerOnFormBtn } from "../../utils/utils";
import { whenVisible, lazyLoadJqueryValidation } from "../lazy-load";
import { disableConversionOnLeaving } from "../modals/conversion-on-leaving";

const NUMBER_SELECTION_REQUEST_API_URL =
  "/api/forms/create-number-selection-request/";

export function initNumberSelectionModal() {
  const numberSelectionForm = $("#number_selection_form"),
    btn = numberSelectionForm.find(".button"),
    btnText = btn.text().trim();

  whenVisible(numberSelectionForm, async () => {
    await lazyLoadJqueryValidation();

    let numberSelectionFormValidator = numberSelectionForm.validate({
      rules: {
        desired_number: {
          required: true
        },
        comment: {
          required: false
        },
        contact_person: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        contact_number: {
          required: true,
          regexp: PHONE_REGEXP
        }
      }
    });

    numberSelectionForm.on("submit", function(e) {
      let form = $(this);

      if (form.valid()) {
        let data = form.serializeObject();

        addSpinnerOnFormBtn(btn);
        post(NUMBER_SELECTION_REQUEST_API_URL, data)
          .done(function() {
            form.find("input, textarea").val("");
            removeSpinnerOnFormBtn(btn, btnText);
            showSuccessFancybox();
            disableConversionOnLeaving();
          })
          .fail(function(response) {
            let errors = JSON.parse(response.responseText);
            mapErrorsToForm(errors, numberSelectionFormValidator);
            removeSpinnerOnFormBtn(btn, btnText);
          });
      }

      e.preventDefault();
    });
  });
}
