import { postToApi } from "../../utils/api";
import { addButtonPreloader, removeButtonPreloader } from "../../utils/utils";

export class AddToTariffComparison {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.isLoading = false;
    this.initialBaseElementWidth = this.baseElement.style.width;

    this.tariffId = this.baseElement.getAttribute("data-id");
    if (!this.tariffId) {
      console.warn(
        "add-to-tariff-comparison is missing data-id attribute",
        this.baseElement
      );
      return;
    }

    this.addEndpoint = this.baseElement.getAttribute("data-add-endpoint");
    if (!this.addEndpoint) {
      console.warn(
        "add-to-tariff-comparison is missing data-add-endpoint attribute",
        this.baseElement
      );
      return;
    }

    this.comparisonUrl = this.baseElement.getAttribute("data-comparison-url");
    if (!this.comparisonUrl) {
      console.warn(
        "add-to-tariff-comparison is missing data-comparison-url attribute",
        this.baseElement
      );
      return;
    }

    this.baseElement.addEventListener("click", event => {
      if (!this.isAdded) {
        event.preventDefault();
        this.add();
      }
    });

    if (this.isAdded) {
      this.setAdded();
    }
  }

  get isAdded() {
    return this.baseElement.classList.contains(
      "add-to-tariff-comparison--added"
    );
  }

  setAdded() {
    this.baseElement.classList.add("add-to-tariff-comparison--added");
    this.baseElement.innerText = "К сравнению";
    this.baseElement.href = this.comparisonUrl;
  }

  startLoading() {
    this.isLoading = true;
    addButtonPreloader(this.baseElement);
  }

  stopLoading() {
    this.isLoading = false;
    removeButtonPreloader(this.baseElement);
  }

  add() {
    if (this.isLoading) return;
    this.startLoading();

    postToApi(this.addEndpoint, { tariff: this.tariffId })
      .then(() => {
        this.setAdded();
      })
      .always(() => {
        this.stopLoading();
      });
  }
}

export default function initAddToTariffComparison() {
  document.querySelectorAll(".add-to-tariff-comparison").forEach(element => {
    new AddToTariffComparison(element);
  });
}
