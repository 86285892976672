import { lazyLoadTooltipster } from "../lazy-load";

export default async function initTooltipster(rootElement) {
  rootElement = rootElement || document.body;
  rootElement = $(rootElement);

  if (rootElement.find(".tooltip").length === 0) return;

  await lazyLoadTooltipster();

  rootElement.find(".tooltip").tooltipster({
    contentAsHTML: true,
    theme: "tooltipster-borderless",
    trigger: "click",
    functionInit: function(instance, helper) {
      var $origin = $(helper.origin);

      var trigger = $origin.attr("data-tooltip-trigger");
      if (trigger) {
        instance.option("trigger", trigger);
      }

      var delay = $origin.attr("data-tooltip-delay");
      if (delay) {
        instance.option("delay", delay);
      }
    }
  });
}
