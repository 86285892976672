import {lazyLoadSlick} from "../lazy-load";
import {breakpoints} from "../../utils/breakpoints";

async function initAutoMdSwiper(element) {
  await lazyLoadSlick();

  const xlPage = Number(element.getAttribute("data-xl-page") || "1");
  const breakpoint = Number(element.getAttribute("data-breakpoint") || breakpoints.xl);

  $(element).slick({
    dots: true,
    speed: 300,
    slidesToShow: xlPage,
    useTransform: true,
    touchThreshold: 20,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: breakpoint,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          infinite: true,
        }
      },
    ],
  });
}

export default function initAutoMdSwiperModule() {
  const elements = document.querySelectorAll(".auto-md-swiper");
  for (const element of elements) {
    initAutoMdSwiper(element);
  }
}
