import { lazyLoadNiceSelect } from "../../lazy-load";

const initElements = new WeakSet();

export function createNiceSelect(baseElement) {
  lazyLoadNiceSelect().then(({ default: NiceSelect }) => {
    if (initElements.has(baseElement)) {
      return;
    }
    new NiceSelect(baseElement, {
      searchable: true,
      searchtext: "Поиск"
    });
    initElements.add(baseElement);
    baseElement.classList.add("nice-select-hidden");
  });
}

export default function initNiceSelect() {
  let elements = document.querySelectorAll(".nice-select-init");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    createNiceSelect(element);
  }
}
