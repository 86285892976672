import debounce from "lodash/debounce";
import { lazyLoadSwiper } from "../lazy-load";

export default function initGalleryWithThumbs() {
  document.querySelectorAll(".gallery-with-thumbs").forEach(element => {
    initSwiper(element);
  });
}

async function initSwiper(baseElement) {
  const { Swiper } = await lazyLoadSwiper();

  let updateGalleryHeightDebounced = debounce(
    () => updateGalleryHeight(baseElement),
    100
  );
  window.addEventListener("resize", updateGalleryHeightDebounced);
  updateGalleryHeight(baseElement);

  let thumbGalleryElement = baseElement.querySelector(
    ".gallery-with-thumbs__thumb-gallery"
  );
  thumbGalleryElement.style.height = "auto";
  var thumbSwiper = new Swiper(thumbGalleryElement, {
    direction: "vertical",
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slidesPerView: "auto",
    centeredSlides: true,
    centeredSlidesBounds: true
  });

  let mainGalleryElement = baseElement.querySelector(
    ".gallery-with-thumbs__main-gallery"
  );
  let mainSwiper = new Swiper(mainGalleryElement, {
    navigation: {
      nextEl: ".gallery-with-thumbs__main-next",
      prevEl: ".gallery-with-thumbs__main-prev"
    },
    thumbs: {
      swiper: thumbSwiper
    },
    on: {
      slideChange: () => {
        // Модуль Thumbs скроллит превью плохо, помогаем ему.
        thumbSwiper.slideTo(mainSwiper.activeIndex);
      }
    }
  });
}

function updateGalleryHeight(baseElement) {
  let mainGalleryElement = baseElement.querySelector(
    ".gallery-with-thumbs__main-gallery"
  );
  let height = mainGalleryElement.getBoundingClientRect().height;
  baseElement.style.height = height + "px";
}
