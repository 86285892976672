import { lazyLoadSwiper } from "../lazy-load";

export async function initArticleDeliverySwiper() {
  if (!document.querySelector(".article-delivery-swiper")) return;

  const { Swiper } = await lazyLoadSwiper();

  new Swiper(".article-delivery-swiper", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  });
}
