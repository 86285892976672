import {lazyLoadIMask} from "../../lazy-load";
import {MASK_REGEXP} from "../../../utils/validation_methods";

export function initPhoneMask($element) {
  $element.mask("+7 ( 000 ) 000 - 00 - 00#", {
    onChange: function(value, event, field) {
      let valueDigits = value.replace(/\D/g, "");
      if (valueDigits.length > 11 && value.indexOf("+7 ( 8") === 0) {
        value = value.replace("+7 ( 8", "+7 ( ");
        field.val(field.masked(value));
      }
    }
  });
}

/**
 * Более мягкая версия initPhoneMask, позволяющая вводить латиницу на случай скрытых цифр.
 */
export function initConcealedPhoneMask($element) {
  $element.mask("+7 ( 000 ) 000 - 00 - 00#", {
    translation: {
      0: {
        pattern: /[\da-zA-Z]/
      },
      "#": {
        pattern: /[\da-zA-Z]/,
        recursive: true
      }
    },
    onChange: function(value, event, field) {
      let valueDigits = value.replace(/[^\da-zA-Z]/g, "");
      if (valueDigits.length > 11 && value.indexOf("+7 ( 8") === 0) {
        value = value.replace("+7 ( 8", "+7 ( ");
        field.val(field.masked(value));
      }
    }
  });
}

export function initMask(element, options) {
  lazyLoadIMask().then(({ default: IMask }) => {
    const mask = new IMask(element, options);
    element.addEventListener("input", event => {
      if (!event.isTrusted) {
        console.warn(
          "Synthetic `input` event on a masked input detected. " +
          "Don't dispatch `input` events manually, they break IMask. " +
          "Dispatch `change` only."
        )
      }
    });
    element.addEventListener("change", event => {
      if (!event.isTrusted) {
        mask.unmaskedValue = event.target.value;
      }
    });
  });
}

export function initMaskMask(element) {
  initMask(element, { mask: MASK_REGEXP });
}

export function initPositiveIntegerMask(element) {
  initMask(element, {
    mask: Number,
    scale: 0,
    thousandsSeparator: " ",
    min: 0,
    autofix: true,
  });
}

/// Возвращает строку из цифр. Строка может быть пустой.
export function cleanMaskedInteger(value) {
  // Удаляем тысячные разделители
  return value.replace(/\D/g, "");
}

export default function initMasksModule() {
  initPhoneMask($(".input-phone"));
  initConcealedPhoneMask($(".input-concealed-phone"));

  initMaskInputs("number-mask", initMaskMask);
  initMaskInputs("pos-int", initPositiveIntegerMask);
}

function initMaskInputs(maskAttr, init) {
  const maskInputs = document.querySelectorAll(`input[data-input-mask="${maskAttr}"]`);
  for (const maskInput of maskInputs) {
    init(maskInput);
  }
}
