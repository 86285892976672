import Cookies from "js-cookie";
import { lazyLoadTooltipster } from "../lazy-load";
import {cleanMaskedInteger} from "../forms/inputs/masks";

export default class ShortLinkGenerator {
  constructor(baseElement) {
    this.baseElement = baseElement;
    lazyLoadTooltipster().then(() => {
      this.init();
    });
  }

  init() {
    // Установленный у нас tooltipster
    this.baseElement.tooltipster({
      contentAsHTML: true,
      theme: "tooltipster-borderless",
      trigger: "custom",
      side: "left",
      triggerClose: {
        click: true,
        scroll: true
      }
    });

    this.form = $(this.baseElement.attr("data-form"));
    this.url = this.baseElement.attr("data-url");
    this.existedLink = null;
    this.params = null;

    this.baseElement.on("click", () => {
      if (this.existedLink && this.searchParams === this.params) {
        this.copyLink();
        this.baseElement.tooltipster("open");
      } else {
        this.sendAjax().then(data => {
          this.existedLink = window.location.origin + data.short_url;
          this.params = this.searchParams;
          this.copyLink();
          this.baseElement.tooltipster("open");
        });
      }
    });
  }

  copyLink() {
    // Скопированный код
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(this.existedLink).select();
    document.execCommand("copy");
    $temp.remove();
  }

  get searchParams() {
    let excludedParams = ["catalog_id", "ordering", "page_size"];
    // Метод сериализации формы в параметры URL адреса
    let paramsArray = this.form.serializeArray();

    let paramsDict = {};
    for (let i = 0; i < paramsArray.length; i++) {
      let name = paramsArray[i].name;
      let value = paramsArray[i].value;

      if (!value) continue;
      if (excludedParams.includes(name)) continue;

      if (this.getInputMask(name) === "pos-int") {
        value = cleanMaskedInteger(value);
      }

      if (name in paramsDict) {
        paramsDict[name] = `${paramsDict[name]}:${value}`;
      } else {
        paramsDict[name] = value;
      }
    }
    return $.param(paramsDict);
  }

  getInputMask(name) {
    const input = this.form.find(`[name="${name}"][data-input-mask]`)[0];
    if (!input) {
      return null;
    }

    return input.getAttribute("data-input-mask");
  }

  prepareData() {
    let params = this.searchParams;
    let url = new URL(window.location);
    let currentPage = url.href.replace(url.origin, "");
    currentPage = currentPage.split("?")[0];
    currentPage = `${currentPage}?${params}`;
    return { with_param_url: currentPage };
  }

  sendAjax() {
    return $.ajax({
      type: "POST",
      url: this.url,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken")
      },
      data: JSON.stringify(this.prepareData()),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      encode: true,
      processData: false
    });
  }
}
