import { post, showSuccessFancybox, mapErrorsToForm } from "../../../modals";
import { PHONE_REGEXP, NAME_REGEXP } from "../../utils/validation_methods";
import { addSpinnerOnFormBtn, removeSpinnerOnFormBtn } from "../../utils/utils";
import { lazyLoadJqueryValidation, whenVisible } from "../lazy-load";
import { disableConversionOnLeaving } from "../modals/conversion-on-leaving";

const CALL_BACK_API_URL = "/api/forms/create-call-back/";

export function initCallBackModal() {
  const form = $(".js-callback-form"),
    btn = form.find(".button"),
    btnText = btn.text().trim();

  whenVisible(form, async () => {
    await lazyLoadJqueryValidation();

    let validator = form.validate({
      rules: {
        name: {
          required: true,
          name_regexp: NAME_REGEXP
        },
        number: {
          required: true,
          regexp: PHONE_REGEXP
        }
      }
    });

    form.on("submit", function(e) {
      const form = $(this);
      if (form.valid()) {
        let data = form.serializeObject();

        addSpinnerOnFormBtn(btn);
        post(CALL_BACK_API_URL, data)
          .done(function() {
            form[0].reset();
            removeSpinnerOnFormBtn(btn, btnText);
            showSuccessFancybox();
            disableConversionOnLeaving();
          })
          .fail(function(response) {
            let errors = JSON.parse(response.responseText);
            mapErrorsToForm(errors, validator);
            removeSpinnerOnFormBtn(btn, btnText);
          });
      }
      e.preventDefault();
    });
  });
}
