import { lazyLoadSlick } from "../lazy-load";

export default function initContactsGuideSlider() {
  let elements = document.querySelectorAll(".contacts-guide-slider");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initSlider(element);
  }
}

async function initSlider(element) {
  await lazyLoadSlick();

  $(element).slick({
    dots: true,
    speed: 300,
    useTransform: true,
    infinite: false,
    arrows: false,
    variableWidth: true,
    centerMode: true
  });
}
