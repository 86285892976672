import throttle from "lodash/throttle";
import { lazyLoadSlick } from "../lazy-load";

const ARROW_WIDTH = 50;
const ARROWS_SPACING = 12; // Отступ между стрелками
const CONTENT_ARROWS_SPACING = 32; // Отступ между контентом слайда и стрелкой

export default async function initMainSlider() {
  if ($(".main-slider").length === 0) return;

  await lazyLoadSlick();

  $(".main-slider__item").css("display", "");

  $(".main-slider")
    .not(".slick-initialized")
    .slick({
      dots: true,
      speed: 300,
      slidesToShow: 1,
      useTransform: true,
      touchThreshold: 20,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            centerPadding: "18px",
            dots: false,
          },
        }
      ],
    });

  $(".main-slider__operator-item").click(function(e) {
    $(".main-slider").slick("slickGoTo", $(this).data("slide"));
    e.preventDefault();
  });

  updatePositions();
  window.addEventListener("resize", throttle(() => updatePositions(), 200));
}

function updatePositions() {
  // Приводим все блоки контента в слайдах к одной ширине
  // и перемещаем кнопки слайдера к правым краям контента
  let maxContentWidth = getMaxContentWidth();
  let horizontalPadding = getContentHorizontalPadding();
  if (maxContentWidth) {
    setContentWidths(maxContentWidth);
    setArrowsLeftOffset(
      horizontalPadding + maxContentWidth + CONTENT_ARROWS_SPACING
    );
    setDotsLeftOffest(horizontalPadding + 5);
  }
}

function getMaxContentWidth() {
  let maxContentWidth = 0;
  $(".main-slider__item-content").each((index, element) => {
    if (element.clientWidth > maxContentWidth) {
      maxContentWidth = element.clientWidth;
    }
  });
  return maxContentWidth || null;
}

function getContentHorizontalPadding() {
  if ($(".main-slider__item-content").length) {
    return $(".main-slider__item-content")[0].getBoundingClientRect().left;
  } else {
    return 0;
  }
}

function setContentWidths(width) {
  // Задаем min-width, чтобы позволить блоку немного расшириться из-за неучтенных изменений ширины
  $(".main-slider__item-content").css("--content-min-width", `${width}px`);
}

function setArrowsLeftOffset(offset) {
  let prevLeft = offset;
  $(".main-slider .slick-prev")
    .css("left", `${prevLeft}px`)
    .css("right", "unset");

  let nextLeft = prevLeft + ARROW_WIDTH + ARROWS_SPACING;
  $(".main-slider .slick-next")
    .css("left", `${nextLeft}px`)
    .css("right", "unset");
}

function setDotsLeftOffest(offset) {
  $(".main-slider .slick-dots")
    .css("left", `${offset}px`)
    .css("right", "unset");
}
