import { PHONE_REGEXP, NAME_REGEXP } from "../../utils/validation_methods";
import GenericFlatForm from "./generic-flat-form";

export function initCooperation() {
  let validator = {
    rules: {
      name: {
        required: true,
        name_regexp: NAME_REGEXP
      },
      email: {
        required: true,
        email: true
      },
      text: {
        required: true
      },
      number: {
        required: true,
        regexp: PHONE_REGEXP
      }
    }
  };
  $(".cooperation-form").each((index, htmlElement) => {
    new GenericFlatForm(htmlElement, validator);
  });
}
