import { RangeInputGroup } from "../forms/groups/range-input-group";

export const INFINITY = "∞";

class InternetRangeInputGroup extends RangeInputGroup {
  constructor(baseElement) {
    super(baseElement);

    this.options = [];
    this.loadOptions();
  }

  loadOptions() {
    const optionElements = this.baseElement.querySelectorAll("[data-option]");
    for (let i = 0; i < optionElements.length; i++) {
      const optionElement = optionElements[i];
      const optionValueStr = optionElement.getAttribute("data-option");
      if (optionValueStr == "unlim") {
        this.options.push([optionElement, this.sliderMax]);
      } else {
        this.options.push([optionElement, Number(optionValueStr)]);
      }
    }
  }

  get baseCssClass() {
    return "internet-range-input-group";
  }

  getSliderOptions() {
    let options = super.getSliderOptions();

    const firstPos = 5;
    const lastPos = 2.5;
    const posStep = (100 - firstPos - lastPos) / (this.options.length - 1);
    this.options.forEach(([_, optVal], optIx) => {
      if (optVal == this.sliderMax) {
        return;
      }

      const optPos = firstPos + posStep * optIx;
      options.range[`${optPos}%`] = [optVal, this.sliderStep];
    });
    options.range.min = [this.sliderMin, this.sliderStep];

    options.format.to = val => {
      if (val === this.sliderMax) {
        return INFINITY;
      } else {
        return Number(val).toFixed(0);
      }
    };
    options.format.from = val => {
      if (val === INFINITY) {
        return this.sliderMax;
      } else {
        const num = Number(val);
        if (Number.isNaN(num)) {
          return this.sliderMin;
        } else {
          return num;
        }
      }
    };

    return options;
  }

  async initSlider() {
    await super.initSlider();

    this.options.forEach(([optElem, optVal]) => {
      optElem.addEventListener("click", () => {
        this.slider.noUiSlider.set(optVal);
      });
    });

    this.slider.noUiSlider.on("update", (values, handle) => {
      let value = values[handle];
      if (value === INFINITY) {
        value = this.sliderMax;
      }

      this.options.forEach(([optElem, optVal]) => {
        if (value >= optVal) {
          optElem.classList.add("internet-range-input-group__option--active");
        } else {
          optElem.classList.remove(
            "internet-range-input-group__option--active"
          );
        }
      });
    });
  }
}

export default function initInternetRangeInputGroup() {
  const elements = document.querySelectorAll(".internet-range-input-group");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new InternetRangeInputGroup(element);
  }
}
