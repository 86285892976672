import {computePosition, autoUpdate, shift} from '@floating-ui/dom';
import {hideCartDropdown} from "../buttons/cart-button";

export function hideAllHeaderDropdowns() {
  const items = Array.prototype.slice.apply(document.querySelectorAll(".header-menu__item"));
  items.forEach(item => {
    item.classList.remove("header-menu__item--active");
  });
}

function attachDropdowns() {
  const items = Array.prototype.slice.apply(document.querySelectorAll(".header-menu__item"));
  items.forEach(item => {
    const headerRow = item.closest(".header__nav-row");
    const link = item.querySelector(".header-menu__item-link");
    const dropdown = item.querySelector(".header-menu__item-dropdown");

    if (!link || !dropdown) {
      return;
    }

    autoUpdate(link, dropdown, () => {
      computePosition(link, dropdown, {
        placement: "bottom-start",
        middleware: [shift({ boundary: headerRow })],
      }).then(({x}) => {
        dropdown.style.left = `${x}px`;
      });
    });
  });
}

function handleTopBurgerClick(event) {
  const button = event.target;
  const headerMenu = button.closest(".header-menu");
  const itemStrip = headerMenu.querySelector(".header-menu__item-strip");
  if (headerMenu.classList.contains("header-menu--active")) {
    headerMenu.classList.remove("header-menu--active");
    document.body.classList.remove("header-menu-lock-scrolling");
  } else {
    headerMenu.classList.add("header-menu--active");
    document.body.classList.add("header-menu-lock-scrolling");

    const bottomOffset = headerMenu.getBoundingClientRect().bottom;
    itemStrip.style.setProperty("--expanded-height", `calc(100vh - ${bottomOffset}px)`);
  }
}

function handleMenuItemLinkClick(event) {
  const link = event.target;
  const selfItem = link.closest(".header-menu__item");
  const dropdown = selfItem.querySelector(".header-menu__item-dropdown");
  const hasDropdown = !selfItem.classList.contains("header-menu__item--no-dropdown");

  if (window.matchMedia("(hover: none)").matches && hasDropdown) {
    // На этом девайсе нельзя навести курсор на пункт меню, меню будет открываться кликом.
    // Отключаем переход по ссылке по клику.
    event.preventDefault();
  }

  hideCartDropdown();

  if (hasDropdown) {
    selfItem.classList.toggle("header-menu__item--active");
  }

  if (dropdown) {
    const height = dropdown.scrollHeight;
    dropdown.style.setProperty("--expanded-dropdown-height", `${height}px`);
  }

  const itemStrip = link.closest(".header-menu__item-strip");
  const stripItems = Array.prototype.slice.apply(itemStrip.querySelectorAll(".header-menu__item"));
  stripItems.forEach(item => {
    if (item !== selfItem) {
      item.classList.remove("header-menu__item--active");
    }
  })
}

function handleOutsideClick(event) {
  const isMenuItem = !!event.target.closest(".header-menu__item");
  if (isMenuItem) {
    return;
  }

  const items = Array.prototype.slice.apply(document.querySelectorAll(".header-menu__item"));
  items.forEach(item => item.classList.remove("header-menu__item--active"));
}

export default function initHeaderMenu() {
  attachDropdowns();

  const burgers = Array.prototype.slice.apply(document.querySelectorAll(".header-menu__top-burger"));
  burgers.forEach(burger => {
    burger.addEventListener("click", event => handleTopBurgerClick(event));
  });

  const itemLinks = Array.prototype.slice.apply(document.querySelectorAll(".header-menu__item-link"));
  itemLinks.forEach(link => {
    link.addEventListener("click", event => handleMenuItemLinkClick(event));
  });

  document.addEventListener("click", event => handleOutsideClick(event));

  const menus = Array.prototype.slice.apply(document.querySelectorAll(".header-menu"));
  menus.forEach(menu => {
    menu.classList.add("header-menu--loaded");
  });
}
