import { lazyLoadSwiper } from "../lazy-load";

export default function initCertificateSlider() {
  document.querySelectorAll(".certificate-slider").forEach(element => {
    initSwiper(element);
  });
}

async function initSwiper(element) {
  const { Swiper } = await lazyLoadSwiper();

  // Add Swiper classes
  element.classList.add("swiper-container");
  element.querySelectorAll(".certificate-slider__wrapper").forEach(element => {
    element.classList.add("swiper-wrapper");
  });
  element.querySelectorAll(".certificate-slider__item").forEach(element => {
    element.classList.add("swiper-slide");
  });

  new Swiper(element, {
    slidesPerView: "auto",
    slidesOffsetBefore: 40,
    slidesOffsetAfter: 40,
    navigation: {
      nextEl: ".certificate-slider__next-button",
      prevEl: ".certificate-slider__prev-button"
    }
  });
}
