import initCatalogView from "./core";
import extraSearchParams from "./plugins/extra-search-params";
import loadMore from "./plugins/load-more";
import sortSelect from "./plugins/sort-select";
import filters from "./plugins/filters";
import dataLayer from "./plugins/data-layer";
import {pushTariffsApiDataLayer} from "../../utils/gtm";
import tariffCardTemplate from "../../../templates/common/tariff_card.ejs";
import {renderNoResultsMessage, renderProviderIcon} from "../templates";
import {store} from "../../react/store";
import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";
import transformParams from "./plugins/transform-params";
import {cleanMaskedInteger} from "../forms/inputs/masks";
import autoLoadMore from "./plugins/auto-load-more";

function renderTariff(tariff) {
  const render = lodashTemplate(tariffCardTemplate, {
    imports: {
      forEach: forEach,
      renderProviderIcon: renderProviderIcon
    }
  });
  const cartTariffs = store.getState().cart.cartTariffs;
  return render({
    tariff: tariff,
    cartTariffs: cartTariffs,
  });
}

function renderTariffPage(tariffs) {
  if (tariffs.length === 0) {
    return renderNoResultsMessage();
  }

  return tariffs.map(renderTariff).join("");
}

const transformMaskedParams = transformParams({
  max_minutes_month_from: v => cleanMaskedInteger(v),
  max_sms_month_from: v => cleanMaskedInteger(v),
  month_fee_from: v => cleanMaskedInteger(v),
  month_fee_to: v => cleanMaskedInteger(v),
});

export function initCatalogViewTariffsCatalog() {
  const elements = document.querySelectorAll(".js-catalog-view--tariffs-catalog");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderTariffPage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        dataLayer({pushToDataLayer: options => pushTariffsApiDataLayer(options)}),
        autoLoadMore({xl: 2}),
      ],
    });
  }
}

export function initCatalogViewTariffsListing() {
  const elements = document.querySelectorAll(".js-catalog-view--tariffs-listing");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderTariffPage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        dataLayer({pushToDataLayer: options => pushTariffsApiDataLayer(options)}),
      ],
    });
  }
}
