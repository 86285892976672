import { layoutUpdate } from "../../utils/utils";
import throttle from "lodash/throttle";
import debounce from "lodash/debounce";
import delay from "lodash/delay";

// Z-index слоя карты. Слои покрытия будут размещаться с бОльшим z-index.
const BASE_LAYER_ZINDEX = 150;
// Отступ от края карты до элементов управления.
const MAP_PADDING_LEFT = 10;
const MAP_PADDING_TOP = 10;
const MAP_PADDING_RIGHT = 10;
// Минимальная суммарная ширина левых и правых кнопок в первом ряду, не считая кнопок слоев, в пикселях.
const FIRST_ROW_LEFT_WIDTH_MIN = 86;
const FIRST_ROW_RIGHT_WIDTH_MIN = 48;
// Горзонтальный и вертикальный отступ между кнопками, в пикселях.
const BUTTON_HSPACING = 10;
const BUTTON_VSPACING = 10;
// Высота кнопок управления картой.
const BUTTON_HEIGHT = 28;

class ProviderCoverageMap {
  constructor(baseElement) {
    this.baseElement = baseElement;

    if (!window.ymaps) {
      console.warn(
        "Яндекс.Карты используются на странице, но скрипт API карт не загружен."
      );
      return;
    }

    this.layersData = JSON.parse(
      this.baseElement.getAttribute("data-layers") || "[]"
    );
    this.resetButtonPositionsThrottled = throttle(() => {
      this.resetButtonPositions();
    }, 500);

    this.scrollingMessage = document.createElement("div");
    this.scrollingMessage.classList.add(
      "provider-coverage-map__bottom-message",
      "provider-coverage-map__bottom-message--hidden",
      "ymaps-message",
      "ymaps-message--dark"
    );
    this.scrollingMessage.innerText =
      "Перейдите в полноэкранный режим, чтобы использовать колесико";
    this.baseElement.appendChild(this.scrollingMessage);
    this.hideScrollingMessageDebounced = debounce(() => {
      this.hideScrollingMessage();
    }, 3000);

    this.initMap();
    this.initLayers();
    this.initScrollZoomTracking();
  }

  initMap() {
    ymaps.ready(() => {
      let minZoom = 0;
      let maxZoom = 23;
      for (const layerData of this.layersData) {
        if (layerData.min_zoom && layerData.min_zoom > minZoom) {
          minZoom = layerData.min_zoom;
        }
        if (layerData.max_zoom && layerData.max_zoom < maxZoom) {
          maxZoom = layerData.max_zoom;
        }
      }

      this.map = new ymaps.Map(
        this.baseElement,
        {
          center: [55.709243, 37.500737], // Москва
          zoom: 9,
          controls: [
            "zoomControl",
            "searchControl",
            "fullscreenControl",
            "geolocationControl"
          ]
        },
        {
          minZoom,
          maxZoom,
          searchControlProvider: "yandex#search"
        }
      );
      // scrollZoom будет включаться в полноэкранном режиме
      this.map.behaviors.disable("scrollZoom");

      let geoCenterName = this.baseElement.getAttribute("data-geo-center");
      if (geoCenterName) {
        this.geocodeAndCenter(geoCenterName);
      }
    });
  }

  geocodeAndCenter(geoCenterName) {
    ymaps.geocode(geoCenterName, { results: 1 }).then(result => {
      let geoObject = result.geoObjects.get(0);
      if (geoObject) {
        let bounds = geoObject.properties.get("boundedBy");
        this.map.setBounds(bounds, { checkZoomRange: true });
      }
    });
  }

  initLayers() {
    ymaps.ready(() => {
      this.layers = [];
      for (let i = 0; i < this.layersData.length; i++) {
        const layerData = this.layersData[i];

        /* eslint-disable */
        let buttonLayout = ymaps.templateLayoutFactory.createClass(`
          <button class="ymaps-toggle-button {% if state.selected %}ymaps-toggle-button--active{% endif %}">
            <span class="ymaps-toggle-button__active-icon" style="background-color: ${layerData.color};"></span>
            <span class="ymaps-toggle-button__inactive-icon"></span>
            {{ data.content }}
          </button>
        `);
        /* eslint-enable */

        let button = new ymaps.control.Button({
          data: {
            content: layerData.display_name
          },
          options: {
            layout: buttonLayout,
            maxWidth: 200,
            floatIndex: this.layersData.length - i
          },
          state: {
            selected: layerData.enabled_by_default
          }
        });
        button.events.add("click", () => {
          setTimeout(() => {
            this.resetLayers();
          }, 0);
        });
        this.map.controls.add(button);

        let mapLayer = new ymaps.Layer(layerData.tile_url_template, {
          tileTransparent: true,
          zIndex: BASE_LAYER_ZINDEX + i
        });

        this.layers.push({
          button,
          mapLayer,
          data: layerData
        });
      }

      this.resetLayers();
      layoutUpdate()
        .then(() => this.loadButtonWidths())
        .then(() => {
          window.addEventListener("resize", () => {
            this.resetButtonPositionsThrottled();
          });
          this.resetButtonPositions();
        });
    });
  }

  resetLayers() {
    for (const layer of this.layers) {
      if (layer.button.state.get("selected")) {
        this.map.layers.add(layer.mapLayer);
      } else {
        this.map.layers.remove(layer.mapLayer);
      }
    }
  }

  /**
   * Записывает в объекты this.layers ширины кнопок включения/выключения слоев в пикселях.
   * Эти ширины используются для расположения кнопок.
   */
  loadButtonWidths() {
    let promiseChain = Promise.resolve();
    for (const layer of this.layers) {
      let button = layer.button;
      promiseChain = promiseChain
        .then(() => {
          return button.getParent().getChildElement(button);
        })
        .then(element => {
          if (element.clientWidth) {
            layer.buttonWidth = element.clientWidth;
          } else {
            // Если элемент еще не успел получить ширину, делаем паузу
            return delay(500).then(() => {
              layer.buttonWidth = element.clientWidth;
            });
          }
        });
    }
    return promiseChain;
  }

  resetButtonPositions() {
    let mapWidth = this.baseElement.clientWidth;

    let firstRowWidth = MAP_PADDING_LEFT + FIRST_ROW_LEFT_WIDTH_MIN;
    for (const layer of this.layers) {
      firstRowWidth += BUTTON_HSPACING + layer.buttonWidth;
    }
    firstRowWidth +=
      BUTTON_HSPACING + FIRST_ROW_RIGHT_WIDTH_MIN + MAP_PADDING_RIGHT;

    if (firstRowWidth > mapWidth) {
      this.moveButtonsToSecondRow();
    } else {
      this.moveButtonsToFirstRow();
    }
  }

  moveButtonsToFirstRow() {
    for (const layer of this.layers) {
      this.map.controls.remove(layer.button);
      this.map.controls.add(layer.button, {
        float: "left",
        position: null,
        maxWidth: layer.buttonWidth
      });
    }
  }

  moveButtonsToSecondRow() {
    let mapWidth = this.baseElement.clientWidth;
    let top = MAP_PADDING_TOP + BUTTON_HEIGHT + BUTTON_VSPACING;
    let left = MAP_PADDING_LEFT;
    for (const layer of this.layers) {
      if (left + layer.buttonWidth + MAP_PADDING_RIGHT > mapWidth) {
        left = MAP_PADDING_LEFT;
        top += BUTTON_HEIGHT + BUTTON_VSPACING;
      }

      this.map.controls.remove(layer.button);
      this.map.controls.add(layer.button, {
        float: "none",
        position: { left, top },
        maxWidth: layer.buttonWidth
      });
      left += layer.buttonWidth + BUTTON_HSPACING;
    }
  }

  get isFullscreen() {
    let fullscreenControl = this.map.controls.get("fullscreenControl");
    return !!fullscreenControl.state.get("selected");
  }

  initScrollZoomTracking() {
    ymaps.ready(() => {
      let fullscreenControl = this.map.controls.get("fullscreenControl");
      fullscreenControl.events.add("fullscreenenter", () => {
        this.map.behaviors.enable("scrollZoom");
      });
      fullscreenControl.events.add("fullscreenexit", () => {
        this.map.behaviors.disable("scrollZoom");
      });

      this.baseElement.addEventListener("wheel", () => {
        if (!this.isFullscreen) {
          this.showScrollingMessage();
          this.hideScrollingMessageDebounced();
        }
      });
    });
  }

  showScrollingMessage() {
    this.scrollingMessage.classList.remove(
      "provider-coverage-map__bottom-message--hidden"
    );
  }

  hideScrollingMessage() {
    this.scrollingMessage.classList.add(
      "provider-coverage-map__bottom-message--hidden"
    );
  }
}

export default function initProviderCoverageMap() {
  let elements = document.querySelectorAll(".provider-coverage-map");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new ProviderCoverageMap(element);
  }
}
