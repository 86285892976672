import { lazyLoadNoUiSlider } from "../../lazy-load";
import { prettyNum } from "../../../utils/utils";

export class RangeInputGroup {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.slider = this.baseElement.querySelector(
      `.${this.baseCssClass}__slider`
    );
    this.sliderMin = Number(this.slider.getAttribute("data-min"));
    this.sliderMax = Number(this.slider.getAttribute("data-max"));
    this.sliderStep = Number(this.slider.getAttribute("data-step"));
    this.sliderValue = Number(this.slider.getAttribute("data-value"));

    this.input = this.baseElement.querySelector(`.${this.baseCssClass}__input`);

    this.initSlider();
  }

  get baseCssClass() {
    return "range-input-group";
  }

  getSliderOptions() {
    return {
      range: {
        min: this.sliderMin,
        max: this.sliderMax
      },
      step: this.sliderStep,
      start: this.sliderValue,
      connect: "lower",
      format: {
        to: Number,
        from: prettyNum
      }
    };
  }

  async initSlider() {
    const { default: noUiSlider } = await lazyLoadNoUiSlider();

    noUiSlider.create(this.slider, this.getSliderOptions());

    this.slider.noUiSlider.on("update", (values, handle) => {
      this.input.value = values[handle];
    });
    this.input.addEventListener("change", () => {
      this.slider.noUiSlider.set(this.input.value);
    });
  }
}

export default function initRangeInputGroup() {
  const elements = document.querySelectorAll(".range-input-group");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new RangeInputGroup(element);
  }
}
