import { renderPlainArticleItem } from "../templates";

class AuthorArticles {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.page = 1;
    this.isLoading = false;
    this.authorArticlesEndpoint = this.baseElement.getAttribute(
      "data-author-article-endpoint"
    );
    this.loadMoreButtons = this.getLoadMoreButtons();
    this.articlesContainer = this.getArticlesContainer();
    if (!this.articlesContainer) {
      console.warn(
        ".author-articles is missing articles container (referenced by data-articles-container attribute)",
        this.baseElement
      );
      return;
    }

    this.loadMoreButtons.forEach(button => {
      button.addEventListener("click", event => {
        this.loadNextPage();
      });
    });
  }

  getLoadMoreButtons() {
    let selector = this.baseElement.getAttribute("data-load-more-buttons");
    if (!selector) return [];

    return Array.from(this.baseElement.querySelectorAll(selector));
  }

  getArticlesContainer() {
    let selector = this.baseElement.getAttribute("data-articles-container");
    if (!selector) return null;

    return this.baseElement.querySelector(selector);
  }

  hideLoadMoreButtons() {
    this.loadMoreButtons.forEach(button => {
      button.style.display = "none";
    });
  }

  startLoading() {
    this.isLoading = true;
    this.loadMoreButtons.forEach(button => {
      if (button.classList.contains("button-gray")) {
        button.classList.add(
          "preloader",
          "preloader--gray",
          "preloader--active"
        );
      }
    });
  }

  stopLoading() {
    this.isLoading = false;
    this.loadMoreButtons.forEach(button => {
      if (button.classList.contains("button-gray")) {
        button.classList.remove(
          "preloader",
          "preloader--gray",
          "preloader--active"
        );
      }
    });
  }

  getAuthorArticles(page) {
    let params = new URLSearchParams();
    params.append("page", page);

    return $.ajax({
      url: this.authorArticlesEndpoint,
      data: params.toString()
    });
  }

  loadNextPage() {
    if (this.isLoading) {
      return;
    }
    this.startLoading();

    this.getAuthorArticles(this.page + 1)
      .then(resp => {
        this.page += 1;

        let renderedResults = resp.results
          .map(article => renderPlainArticleItem(article))
          .join("");
        this.articlesContainer.insertAdjacentHTML("beforeend", renderedResults);

        if (!resp.next) {
          this.hideLoadMoreButtons();
        }
      })
      .always(() => {
        this.stopLoading();
      });
  }
}

export default function initAuthorArticles() {
  document.querySelectorAll(".author-articles").forEach(element => {
    new AuthorArticles(element);
  });
}
